.avatar {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatar img {
    width: 100%;
    height: 100%;
  }
  .recipe-header-avatar .avatar,
  .nav-avatar .avatar {
    width: 30px;
    height: 30px;
    vertical-align: middle;
  }
  .nav-avatar .avatar-text {
    vertical-align: middle;
  }
  a.nav-avatar {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid $gray-300;
    vertical-align: middle;
    text-align: center;
    background-color: $gray-300;
  }