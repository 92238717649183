.recipe-image {
    padding: $spacer;
    background-color: $white;
    box-shadow: $box-shadow-sm;
    border-radius: $border-radius-lg;
    text-align: center;
    
    > img {
        @include img-fluid();
        border-radius: $border-radius-lg;
        max-height: 400px;
    }
    > .recipe-image-badge {
        position:absolute;
        top: $spacer/2;
        left: $spacer/2;
    }   
    > .recipe-image-utility {
        position:absolute;
        top: $spacer/2;
        right: $spacer/2;
    }
}
.recipe-card {
    padding: $spacer;
    box-shadow: $box-shadow-sm;
    border-color: $gray-300;
    cursor:pointer;
}

@include media-breakpoint-only(xs){
.card-img-sm {
    width: 100%;
    object-fit: cover;
        height: 100%vw;
    }
}
@include media-breakpoint-up(sm){
    .card-img-sm{
        width: 100%;
        object-fit:cover;
        height:15vw;
    }
}